<template>
  <div class="s_bf">
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="type" label="消息类型" name="type">
          <a-select v-model:value="form.type" placeholder="请选择消息类型">
            <a-select-option :value="item.type" v-for='item in typeList' :key='item'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="project" label="触发项目" name="project">
          <a-input v-model:value="form.project" placeholder='请输入触发项目' />
        </a-form-item>
        <a-form-item ref="condition" label="触发时间说明" name="condition">
          <a-input v-model:value="form.condition" placeholder='请输入触发时间说明'  />
        </a-form-item>
        <a-form-item ref="wx_content" label="消息订阅内容" name="wx_content" >
          <a-textarea v-model:value="form.wx_content" showCount :maxlength="200" placeholder="请输入消息订阅内容" :rows="4" />
        </a-form-item>
        <a-form-item ref="email_content" label="站内信内容" name="email_content" >
          <a-textarea v-model:value="form.email_content" showCount :maxlength="200" placeholder="请输入站内信内容" :rows="4" />
        </a-form-item>
        <a-form-item ref="status" label="是否应用" name="status">
          <a-radio-group v-model:value="form.status">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 3 }">
          <a-button type="primary" @click="onSubmit" :loading='loading'>
            保存
          </a-button>
        </a-form-item>
      </a-form>
  </div>
</template>
<script>
  import { ref, onMounted, reactive, toRefs, inject,watchEffect } from 'vue';
  import { message } from 'ant-design-vue';
  import { $iscode } from "@/utils/app";
  import { messProjectAdd, messProjectEdit} from "@/api/brand";
  // 初始化默认筛选项数值
  let defForm = {
    project: '',
    type: null,
    email_content: '',
    wx_content:'',
    condition:'',
    status:1,
  }
  export default {
    name:'msgSubscribeForm',
    components: {},
    props: {
      formData: {
        type: Object
      },
      typeList: {
        type: Array
      },
      type:{
        type:String
      }
    },
    setup(props,{emit}) {
      //pass
      const form = ref({ ...defForm });
      const state = reactive({
        loading:false,
        typeList:[],
        formData:{},
        labelCol: { span: 3 },
        wrapperCol: { span: 18 },
        rules: {
          type:[
          { required: true, message: '请选择消息类型'}
          ],
          project: [
            { required: true, message: '请输入触发项目'}
          ],
          condition: [
            { required: true, message: '请输入触发时间说明'}
          ],
          wx_content: [
            { required: true, message: '请输入消息订阅内容'}
          ],
          email_content: [
            { required: true, message: '请输入站内信内容'}
          ],
        }
      });
      const ruleForm = ref()
      const _lodash = inject("_lodash");
      const onSubmit = () => {
        ruleForm.value.validate().then(async() => {
          const hide = message.loading('正在加载中...', 0);
          state.loading = true
          let res
          if (form.value.id) {
            res = await messProjectEdit(form.value).then(res => res.data);
          } else {
            res = await messProjectAdd(form.value).then(res => res.data);
          }
          if ($iscode(res)) {
            message.success(res.msg);
            emit('onsubmit')
          } else {
            message.error(res.msg);
          }
          setTimeout(hide);
          state.loading = false
        }).catch(error => {
            console.log('error', error);
        });
      }
      onMounted(() => {
        console.log(props)
        state.typeList = props.typeList
        if(props.type == 'edit'){
          form.value = props.formData
        }
      });
      return {
        form,
        ...toRefs(state),
        ruleForm,
        onSubmit
      }
    }
  }
</script>
<style>
</style>